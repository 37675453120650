import { log } from "./logger";
import { SESSIONS_ACTIONS } from "../actions/listOfSessionsActions";

const moduleName = "helper";

export const sessionConnected = (sessionConnectEvent) => {
  log(moduleName, "connected to session", { sessionConnectEvent });
}

export const sessionDisconnected = (sessionConnectEvent) => {
  sessionConnectEvent.preventDefault();
  log(moduleName, "Disconnected from session", { sessionConnectEvent });
}

export const streamCreated = (streamEvent, dispatch) => {
  const stream = Object.assign({}, streamEvent.stream);
  log(moduleName, "local stream published in private session", { stream });
}

export const streamDestroyed = (streamEvent, dispatch) => {
  const stream = Object.assign({}, streamEvent.stream);
  log(moduleName, "local stream destroyed in private session", { stream });
}

export const publicStreamCreated = (streamEvent, publicSession, dispatch) => {
  const stream = Object.assign({}, streamEvent.stream);

  if (stream.connection.connectionId === publicSession.connection.connectionId) {
    log(moduleName, "local stream created", { stream });
  } else {
    log(moduleName, "remote stream created", { stream });

    dispatch({
      type: SESSIONS_ACTIONS.STREAM_AVAILABLE,
      payload: { value: stream },
    });
  }
}

export const publicStreamDestroyed = (streamEvent, publicSession, dispatch) => {
  const stream = Object.assign({}, streamEvent.stream);

  if (stream.connection.connectionId === publicSession.connection.connectionId) {
    log(moduleName, "local stream terminated", { stream });
  } else {
    log(moduleName, "remote stream terminated", { stream });

    dispatch({
      type: SESSIONS_ACTIONS.STREAM_UNAVAILABLE,
      payload: { value: stream },
    });
  }
}

export const publicStreamCreatedFromModerator = (streamEvent) => {
  log(moduleName, "Moderator stream created", { streamEvent });
}

export const publicStreamDestroyedFromModerator = (streamEvent) => {
  log(moduleName, "Moderator stream destroyed", { streamEvent });
}

export const privateStreamCreated = (streamEvent, privateSession, dispatch) => {
  const stream = Object.assign({}, streamEvent.stream);

  if (stream.connection.connectionId === privateSession.connection.connectionId) {
    log(moduleName, "local stream created in private session", { stream });
  } else {
    log(moduleName, "remote stream created in private session", { stream });

    dispatch({
      type: SESSIONS_ACTIONS.PRIVATE_STREAM_AVAILABLE,
      payload: { value: stream },
    });
  }
}

export const privateStreamDestroyed = (streamEvent, privateSession, dispatch) => {
  const stream = Object.assign({}, streamEvent.stream);


  if (stream.connection.connectionId === privateSession.connection.connectionId) {
    log(moduleName, "local stream destroyed in private session", { stream });
  } else {
    log(moduleName, "remote stream destroyed in private session", { stream });

    dispatch({
      type: SESSIONS_ACTIONS.PRIVATE_STREAM_UNAVAILABLE,
      payload: { value: stream },
    });
  }
}

export const connectionCreated = (event, moderatorId, dispatch) => {
  const userId = event.connection.data;
  if (userId === moderatorId) {
    log(moduleName, "The moderator has joined the session", { event });
  } else {
    log(moduleName, "A participant has joined the session", { event });
    dispatch({
      type: SESSIONS_ACTIONS.VIEWER_JOINED,
      payload: { value: 1 },
    });
  }
}

export const connectionDestroyed = (event, moderatorId, dispatch) => {
  const userId = event.connection.data;
  if (userId === moderatorId) {
    log(moduleName, "The moderator has left the session", { event });
  } else {
    log(moduleName, "A participant has left the session", { event });
  dispatch({
    type: SESSIONS_ACTIONS.VIEWER_LOST,
    payload: { value: -1 },
  });
  }
}

export const publicConnectionDestroyed = (event, moderatorId, dispatch) => {

  const userId = event.connection.data;
  if (userId === moderatorId) {
    log(moduleName, "The moderator has left the session", { event });

    dispatch({
      type: SESSIONS_ACTIONS.MODERATOR_LOST,
      payload: { value: null },
    });
  } else {
    log(moduleName, "Other visitor left the session", { event });
  }
}

export const accessAllowed = (event) => {
  log(moduleName, "Access to devices granted", { event });
}

export const accessDenied = (event) => {
  log(moduleName, "Access denied to devices", { event });
}

export const accessDialogOpened = (event) => {
  log(moduleName, "Authorization dialog opened", { event });
}

export const accessDialogClosed = (event) => {
  log(moduleName, "Authorization dialog closed", { event });
}

export const mediaStopped = (event) => {
  log(moduleName, "Publisher stopped publishing the stream", { event });
}

export const privateCallAsked = (event, dispatch) => {
  log(moduleName, "A subscriber asked for a private chat", { event });
  const from = event.from
  dispatch({
    type: SESSIONS_ACTIONS.P2P_SESSION_ASKED,
    payload: { value: { from } },
  });
}

export const privateCallAnswered = (event, dispatch) => {
  log(moduleName, "Publisher answered to private chat", { event });
  const from = event.from
  dispatch({
    type: SESSIONS_ACTIONS.P2P_SESSION_ANSWERED,
    payload: { value: { from } },
  });
}

export const privateCallEnded = (event, dispatch) => {
  log(moduleName, "Publisher ended the private chat", { event });
  const from = event.from
  dispatch({
    type: SESSIONS_ACTIONS.P2P_SESSION_ENDED,
    payload: { value: { from } },
  });
}

export const isWebRTCSupported = () => {
  const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const chrome = /CriOS|Chrome/i.test(navigator.userAgent);
  const firefox = /FxiOS|Firefox/i.test(navigator.userAgent);

  if (isiOS && (chrome || firefox)) {
    return false;
  }
  return true;
}

export const inPrivateReceived = (event, dispatch) => {
  log(moduleName, "Inform moderator in private session", { event });

  dispatch({
    type: SESSIONS_ACTIONS.MODERATOR_IN_PRIVATE,
    payload: { value: null },
  });
}

export const outPrivateReceived = (event, dispatch) => {
  log(moduleName, "Inform moderator out private session", { event });

  dispatch({
    type: SESSIONS_ACTIONS.MODERATOR_OUT_PRIVATE,
    payload: { value: null },
  });
}
