import { useEffect, useContext, useRef, useState } from 'react';
import { Button } from 'semantic-ui-react'
import { log } from "../../modules/logger";
import { connectToPublicSession, subscribeToStreamInSession, subscribeToPrivateStreamInSession, requestPrivateCall, VIEWER_STATE, connectToPrivateSession, publishToPrivateSession, unpublishAndDisconnectFromPrivateSession, informModeratorSessionLeft } from '../../actions/sessionsActions';
import AppContext from "../../contexts/appContext";
import './Stream.css';
import { isWebRTCSupported } from '../../modules/helper';
import NoStream from "../NoStream/NoStream";

const moduleName = "scr-stream"

const STREAM_STATE = {
  IDLE: "IDLE",
  CONNECTING_PUBLIC: "CONNECTING_PUBLIC",
  CONNECTED_PUBLIC: "CONNECTED_PUBLIC",
  AVAILABLE_STREAM_IN_PUBLIC: "AVAILABLE_STREAM_IN_PUBLIC",
  PLAYING_PUBLIC: "PLAYING_PUBLIC",
  CONNECTING_PRIVATE: "CONNECTING_PRIVATE",
  CONNECTED_PRIVATE: "CONNECTED_PRIVATE",
  DISCUSSING_PRIVATE: "DISCUSSING_PRIVATE",
  BACK_TO_PUBLIC: "BACK_TO_PUBLIC",
}

const MODERATOR_STATE = {
  IDLE: "Noch nicht verbunden", //"Not yet connected!",
  IN_PUBLIC: "Live Video", //"You're watching the public live",
  IN_PRIVATE: "Sie sind im Privat-Gespräch", //You're in private",
  IN_PRIVATE_WITH_OTHER: "Privatgespräch" , //private call in progress"
}

const SESSION_STATE = {
  NO_LIVE: "Zur Zeit läuft keine Übertragung. \n Schauen Sie doch später wieder vorbei. \n Ich freue mich auf Sie.", // No live at this time!",
  LIVE_PAUSED: "Schön das Sie da sind. \n Ich bin gerade im Gespräch. \n\n Sie können kurz warten, oder später wieder vorbeischauen." , //Live is paused",
  TALK: "Private Beratung starten", //Let's Talk in Private"
  STOP: "Gespräch beenden", //Stop Call"
}

const isInPublicWithoutStream = (state) => (
  state === STREAM_STATE.CONNECTING_PUBLIC ||
  state === STREAM_STATE.CONNECTED_PUBLIC
);

const isViewingStream = (state) => (
  state === STREAM_STATE.AVAILABLE_STREAM_IN_PUBLIC ||
  state === STREAM_STATE.PLAYING_PUBLIC ||
  state === STREAM_STATE.BACK_TO_PUBLIC ||
  state === STREAM_STATE.CONNECTING_PRIVATE ||
  state === STREAM_STATE.CONNECTED_PRIVATE ||
  state === STREAM_STATE.DISCUSSING_PRIVATE
)

const isInPrivate = (state) => (
  state === STREAM_STATE.CONNECTING_PRIVATE ||
  state === STREAM_STATE.CONNECTED_PRIVATE ||
  state === STREAM_STATE.DISCUSSING_PRIVATE
);

const isIdleOrInPublic = (state) => (
  state === STREAM_STATE.IDLE ||
  state === STREAM_STATE.CONNECTING_PUBLIC ||
  state === STREAM_STATE.CONNECTED_PUBLIC ||
  state === STREAM_STATE.AVAILABLE_STREAM_IN_PUBLIC ||
  state === STREAM_STATE.PLAYING_PUBLIC ||
  state === STREAM_STATE.BACK_TO_PUBLIC
);

const isInPublic = (state) => (
  state === STREAM_STATE.CONNECTING_PUBLIC ||
  state === STREAM_STATE.CONNECTED_PUBLIC ||
  state === STREAM_STATE.AVAILABLE_STREAM_IN_PUBLIC ||
  state === STREAM_STATE.PLAYING_PUBLIC ||
  state === STREAM_STATE.BACK_TO_PUBLIC
);

function Stream({ room, dispatch }) {
  const appState = useContext(AppContext);
  const streamPrivateLocalElt = useRef(null);
  const streamPrivateRemoteElt = useRef(null);
  const streamPublicElt = useRef(null);
  const [streamState, setStreamState] = useState(STREAM_STATE.IDLE);
  const [moderatorState, setModeratorState] = useState(MODERATOR_STATE.IN_PRIVATE_WITH_OTHER); 
  const [hasMediaWhenNoStream, setHasMediaWhenNoStream] = useState(false);
  const [hasMediaWhenPrivateCall, setHasMediaWhenPrivateCall] = useState(false);

  useEffect(() => {
    log(moduleName, `Stream state changed to ${streamState}`)
    switch (streamState) {
      case STREAM_STATE.CONNECTING_PUBLIC:
        connectToPublicSession(dispatch, room);
        break;
      case STREAM_STATE.CONNECTED_PUBLIC:
        break;
      case STREAM_STATE.AVAILABLE_STREAM_IN_PUBLIC:
        subscribeToStreamInSession(appState.publicSession, appState.stream, streamPublicElt.current, dispatch);
        break;
      case STREAM_STATE.CONNECTING_PRIVATE:
        connectToPrivateSession(dispatch, room);
        break;
      case STREAM_STATE.CONNECTED_PRIVATE:
        publishToPrivateSession(dispatch, streamPrivateLocalElt.current, appState.privateSession, "Guest");
        break;
      case STREAM_STATE.DISCUSSING_PRIVATE:
        subscribeToPrivateStreamInSession(appState.privateSession, appState.privateStream, streamPrivateRemoteElt.current, dispatch);
        break;
      case STREAM_STATE.BACK_TO_PUBLIC:
        unpublishAndDisconnectFromPrivateSession(appState.privateSession, appState.privatePublisher, appState.privateSubscriber, dispatch);
        informModeratorSessionLeft(appState.publicSession);
        setStreamState(STREAM_STATE.CONNECTED_PUBLIC);
        break;
      default:
        break;
    }
  }, [streamState])

  useEffect(() => {
    const viewerState = appState.viewerState;
    log(moduleName, `Viewer state changed to ${viewerState}`)

    switch (viewerState) {
      case VIEWER_STATE.CONNECTED_PUBLIC:
        break;
      case VIEWER_STATE.VIEWING_PUBLIC:
        setStreamState(STREAM_STATE.PLAYING_PUBLIC);
        break;
      case VIEWER_STATE.LOST_PUBLIC:
        setStreamState(STREAM_STATE.CONNECTED_PUBLIC);
        setModeratorState(MODERATOR_STATE.IN_PUBLIC);
        break;
      case VIEWER_STATE.PRIVATE_ANSWERED:
        setStreamState(STREAM_STATE.CONNECTING_PRIVATE);
        break;
      case VIEWER_STATE.CONNECTED_PRIVATE:
        setStreamState(STREAM_STATE.CONNECTED_PRIVATE);
        break;
      case VIEWER_STATE.STREAMING_PRIVATE:
        setModeratorState(MODERATOR_STATE.IN_PRIVATE);          // KK 28.11.21 added this line to display correct message to viewer in private call
        break;
      case VIEWER_STATE.LOST_PRIVATE:
        setStreamState(STREAM_STATE.BACK_TO_PUBLIC);
        break;
      default:
        break;
    }
  }, [appState.viewerState]);

  useEffect(() => {
    // Detect moderator stream in public session
    const stream = appState.stream;

    if (stream && !stream.destroyed && isInPublicWithoutStream(streamState)) {
      setStreamState(STREAM_STATE.AVAILABLE_STREAM_IN_PUBLIC);
    }
  }, [appState.stream, streamState])

  useEffect(() => {
    // Detect moderator stream in private session
    const stream = appState.privateStream;
    if (stream && !stream.destroyed && isInPrivate(streamState)) {
      setStreamState(STREAM_STATE.DISCUSSING_PRIVATE)
    }
  }, [appState.privateStream, streamState])

  useEffect(() => {
    if (appState.moderatorInPrivate || room.private.state === "active") {
      setModeratorState(isInPrivate(streamState) ? MODERATOR_STATE.IN_PRIVATE : MODERATOR_STATE.IN_PRIVATE_WITH_OTHER);
    } else {
      setModeratorState(MODERATOR_STATE.IN_PUBLIC);
    }
  }, [appState.moderatorInPrivate])

  useEffect(() => {
    setStreamState(STREAM_STATE.CONNECTING_PUBLIC);
  }, [])

  useEffect(() => {
    if(appState.publicSessionError) {
      setTimeout(() => {
        connectToPublicSession(dispatch, room);
      }, 15000);
    }
  }, [appState.publicSessionError])

  useEffect(() => {
    if(appState.customization) {
      setHasMediaWhenNoStream(appState.customization["noStreamMedia"] && appState.customization["noStreamMedia"].length > 0);
      setHasMediaWhenPrivateCall(appState.customization["privateCallMedia"] && appState.customization["privateCallMedia"].length > 0);
    }
  }, [appState.customization])

  const onPrivateCall = () => {
    requestPrivateCall(appState.publicSession, dispatch);
  }

  const onStopPrivateCall = () => {
    setStreamState(STREAM_STATE.BACK_TO_PUBLIC);
  }

  return (
    <div className="Stream">
      <div className={isIdleOrInPublic(streamState) ? "Stream-PublicAreaVisible" : "Stream-PublicAreaHidden"} >
        <div className="Stream-Play" ref={streamPublicElt}>
          {isInPublicWithoutStream(streamState) && moderatorState !== MODERATOR_STATE.IN_PRIVATE_WITH_OTHER && (
            <>
              {hasMediaWhenNoStream && (
                <NoStream reason="nostream" />
              )}
              {!hasMediaWhenNoStream && (
                <div className="Stream-MutedStream">
                  <span className="Stream-MutedStream-text">{SESSION_STATE.NO_LIVE}</span>
               </div>
              )}
            </>
          )}
          {moderatorState === MODERATOR_STATE.IN_PRIVATE_WITH_OTHER && (
            <div className={hasMediaWhenPrivateCall ? "Stream-MutedStreamMedia" : "Stream-MutedStream"}>
              {hasMediaWhenPrivateCall && (
                <NoStream reason="noprivate" />
              )}
              {!hasMediaWhenPrivateCall && (
                <span className="Stream-MutedStream-text">{SESSION_STATE.LIVE_PAUSED}</span>
              )}
            </div>
          )}
        </div>
      </div>
      {isInPrivate(streamState) && (
        <>
          <div className="Stream-Play-Remote" id="privateRemote" ref={streamPrivateRemoteElt}></div>
          <div className="Stream-Play-Local" id="privateLocal" ref={streamPrivateLocalElt}></div>
        </>
      )}

      <div className="Stream-Controls" >
        { isViewingStream(streamState) && (
          <>
            <div className="Stream-state">{moderatorState}</div>
            {isInPublic(streamState) && (
              <Button size="big" className="Stream-Button" onClick={onPrivateCall} disabled={streamState !== STREAM_STATE.PLAYING_PUBLIC || !isWebRTCSupported()}>{SESSION_STATE.TALK}</Button>
            )}
            {isInPrivate(streamState) && (
              <Button size="big" className="Stop-Stream-Button" onClick={onStopPrivateCall} >{SESSION_STATE.STOP}</Button>
            )}
          </>
          )}
      </div>
    </div>

  );
}

export default Stream;
