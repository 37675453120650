import { useContext, useEffect, useState } from 'react';
import AppContext from "../../contexts/appContext";
import { log } from "../../modules/logger";
import { listSessions } from '../../actions/sessionsActions';

import Stream from "../Stream/Stream";
import NoStream from "../NoStream/NoStream";

import './EmbeddedView.css';

const moduleName = "embedded-view";


function EmbeddedView({ dispatch }) {

  const appState = useContext(AppContext);

  const [sessions, setSessions] = useState([]);

  useEffect(async () => {
    listSessions(dispatch).then(() => {});
  }, [dispatch])

  useEffect(() => {
    async function waitAndAsk() {
      setTimeout(async () => {
        await listSessions(dispatch);
      }, 15000);
    }
    log(moduleName, "sessions changed", { sessions: appState.rooms });
    setSessions(appState.rooms);
    if (appState.rooms.length === 0) {
      waitAndAsk().then(() => {});
    }
  }, [appState.rooms]);

  const renderSessions = () => {
    if (!!sessions.length) {
      return (
        sessions.map((room, index) => (
          <Stream room={room} key={index} dispatch={dispatch} />
        ))
      )
    }
    return (
      <div className="Stream-Video">
        <div className="Stream-Play">
          <NoStream reason="nostream" />
        </div>
      </div>
    );
  };


  return (
    <div className="EmbeddedView">
      {renderSessions()}
    </div>
  );
}

export default EmbeddedView;
