import { log } from "../modules/logger";
import { authenticate } from "../modules/auth";
import { setModeratorID } from "../modules/tok";

export const AUTH_ACTIONS = {
  AUTHENTICATE_IN_PROGRESS: "AUTHENTICATE_IN_PROGRESS",
  AUTHENTICATE_SUCCEEDED: "AUTHENTICATE_SUCCEEDED",
  AUTHENTICATE_FAILED: "AUTHENTICATE_FAILED",

  SIGNOUT_SUCCEEDED: "SIGNOUT_SUCCEEDED"
};

const moduleName = "authActions"

export const signIn = async (username, password, dispatch) => {
  log(moduleName, "execute action", { action: "signIn", for: username });

  dispatch({
    type: AUTH_ACTIONS.AUTHENTICATE_IN_PROGRESS,
    payload: { value: null },
  });

  try {
    const moderator = await authenticate(username, password);

    if(moderator) {
      setModeratorID(moderator.id);
      dispatch({
        type: AUTH_ACTIONS.AUTHENTICATE_SUCCEEDED,
        payload: { value: moderator },
      });
    } else {
      dispatch({
        type: AUTH_ACTIONS.AUTHENTICATE_FAILED,
        payload: { value: null },
      });
    }
  } catch (err) {
    dispatch({
      type: AUTH_ACTIONS.AUTHENTICATE_FAILED,
      payload: { value: null },
    });
  }
};

export const signOut = async (dispatch) => {
  dispatch({
    type: AUTH_ACTIONS.SIGNOUT_SUCCEEDED,
    payload: { value: null },
  })
}
