//import { initSession } from "@opentok/client";
import { log, error } from "../modules/logger";
import { getSessions, createTokenForViewer, createTokenForP2P } from "../modules/sessions";
import { connectToSession, initSession, initPublisher, listenToPublisherEventsInPrivateSession, listenToViewerSessionEvents, publishToSession, createSubscriber, sendMessage, listenToPrivateSessionEvents, unlistenFromPublisherEventsInPrivateSession, disconnectFromSession, unlistenFromViewerSessionEvents } from "../modules/tok";
import { SESSIONS_ACTIONS } from "./listOfSessionsActions";

const moduleName = "sessionsActions";

export const VIEWER_STATE = {
  IDLE: "IDLE",
  CONNECTED_PUBLIC: "CONNECTED_PUBLIC",
  VIEWING_PUBLIC: "VIEWING_PUBLIC",
  LOST_PUBLIC: "LOST_PUBLIC",
  ONERROR: "ONERROR",
  REQUESTED_PRIVATE: "REQUESTED_PRIVATE",
  PRIVATE_ANSWERED: "PRIVATE_ANSWERED",
  CONNECTED_PRIVATE: "CONNECTED_PRIVATE",
  STREAMING_PRIVATE: "STREAMING_PRIVATE",
  VIEWING_PRIVATE: "VIEWING_PRIVATE",
  LOST_PRIVATE: "LOST_PRIVATE",
}

export const listSessions = async (dispatch) => {
  log(moduleName, "execute action", { action: "list sessions" });

  dispatch({
    type: SESSIONS_ACTIONS.GET_SESSIONS_IN_PROGRESS,
    payload: { value: null },
  });

  try {
    const sessions = await getSessions();

    dispatch({
      type: SESSIONS_ACTIONS.GET_SESSIONS_SUCCEEDED,
      payload: { value: sessions },
    });
  } catch (err) {
    error(moduleName, "Can't get sessions", { err });
    dispatch({
      type: SESSIONS_ACTIONS.GET_SESSIONS_FAILED,
      payload: { value: [] },
    });
  }
};

export const connectToPrivateSession = async (dispatch, room) => {
  log(moduleName, "execute action", { action: "connect to session" });

  dispatch({
    type: SESSIONS_ACTIONS.CONNECT_TO_SESSION_IN_PROGRESS,
    payload: { value: null },
  });

  try {

    const privateToken = await createTokenForP2P(room.private.sessionId);

    const privateSession = initSession(room.apiKey, room.private.sessionId);

    if (!privateSession) {
      error(moduleName, "Can't connect to the private session - no session");
      dispatch({
        type: SESSIONS_ACTIONS.CONNECT_TO_PRIVATE_SESSION_FAILED,
        payload: { value: null },
      });
      return;
    }

    listenToPrivateSessionEvents(privateSession, dispatch);

    await connectToSession(privateSession, privateToken);

    dispatch({
      type: SESSIONS_ACTIONS.CONNECT_TO_PRIVATE_SESSION_SUCCEEDED,
      payload: { value: { privateSession, privateToken } },
    });
  } catch (err) {
    error(moduleName, "Can't connect to the private session", { err });
    dispatch({
      type: SESSIONS_ACTIONS.CONNECT_TO_PRIVATE_SESSION_FAILED,
      payload: { value: null },
    });
  }
}

export const connectToPublicSession = async (dispatch, room) => {
  log(moduleName, "execute action", { action: "connect to public session" });

  dispatch({
    type: SESSIONS_ACTIONS.CONNECT_TO_SESSION_IN_PROGRESS,
    payload: { value: null },
  });

  const publicToken = await createTokenForViewer(room.public.sessionId);
  if(!publicToken) {
    error(moduleName, "Can't connect to the session - no token");
    dispatch({
      type: SESSIONS_ACTIONS.CONNECT_TO_PUBLIC_SESSION_FAILED,
      payload: { value: null },
    });
    return;
  }

  try {
    const publicSession = initSession(room.apiKey, room.public.sessionId);

    if (!publicSession) {
      error(moduleName, "Can't connect to the session - no session");
      dispatch({
        type: SESSIONS_ACTIONS.CONNECT_TO_PUBLIC_SESSION_FAILED,
        payload: { value: null },
      });
      return;
    }

    listenToViewerSessionEvents(publicSession, room.userId, dispatch);

    await connectToSession(publicSession, publicToken);

    dispatch({
      type: SESSIONS_ACTIONS.CONNECT_TO_PUBLIC_SESSION_SUCCEEDED,
      payload: { value: { publicSession, publicToken } },
    });
  } catch (err) {
    error(moduleName, "Can't connect to the public session", { err });
    dispatch({
      type: SESSIONS_ACTIONS.CONNECT_TO_PUBLIC_SESSION_FAILED,
      payload: { value: null },
    });
  }
}

export const publishToPrivateSession = async (dispatch, videoElt, privateSession, name) => {
  log(moduleName, "execute action", { action: "publish to the private session" });

  try {
    const privatePublisher = await initPublisher(videoElt, name);

    if (!privatePublisher) {
      error(moduleName, "Can't init a publisher", {});
    }

    listenToPublisherEventsInPrivateSession(privatePublisher, privateSession, dispatch);

    publishToSession(privateSession, privatePublisher);

    dispatch({
      type: SESSIONS_ACTIONS.SET_PRIVATE_PUBLISHER,
      payload: { value: { privatePublisher } },
    });

  } catch (err) {
    error(moduleName, "Can't publish to the private session", { err });
    dispatch({
      type: SESSIONS_ACTIONS.SET_PRIVATE_PUBLISHER_FAILED,
      payload: { value: null },
    });
  }
}

export const updateState = async (dispatch, state) => {
  log(moduleName, "execute action", { action: "update state" });

  dispatch({
    type: SESSIONS_ACTIONS.UPDATE_STATE,
    payload: { value: state },
  });
}

export const subscribeToStreamInSession = async (session, stream, videoElt, dispatch) => {
  log(moduleName, "execute action", { action: "watch stream" });

  dispatch({
    type: SESSIONS_ACTIONS.WATCH_STREAM_IN_PROGRESS,
    payload: { value: null },
  });

  try {
    const subscriber = await createSubscriber(session, stream, videoElt, true);

    dispatch({
      type: SESSIONS_ACTIONS.WATCH_STREAM_SUCCEEDED,
      payload: { value: { subscriber } },
    });

  } catch (err) {
    error(moduleName, "error subscribing to stream", { err });
    dispatch({
      type: SESSIONS_ACTIONS.WATCH_STREAM_FAILED,
      payload: { value: null },
    });
  }
}

export const unsubscribeToStreamInSession = async (session, stream) => {
  log(moduleName, "execute action", { action: "unwatch stream" });

  try {
    session.unsubscribe(stream);

  } catch (err) {
    error(moduleName, "error unsubscribing to stream", { err });
  }
}

export const subscribeToPrivateStreamInSession = async (session, stream, videoElt, dispatch) => {
  log(moduleName, "execute action", { action: "watch stream" });

  dispatch({
    type: SESSIONS_ACTIONS.WATCH_STREAM_IN_PROGRESS,
    payload: { value: null },
  });

  try {
    const privateSubscriber = await createSubscriber(session, stream, videoElt, true);

    dispatch({
      type: SESSIONS_ACTIONS.WATCH_PRIVATE_STREAM_SUCCEEDED,
      payload: { value: { privateSubscriber } },
    });

  } catch (err) {
    error(moduleName, "Can't subscribe to the private session", { err });
    dispatch({
      type: SESSIONS_ACTIONS.WATCH_PRIVATE_STREAM_FAILED,
      payload: { value: null },
    });
  }
}

export const requestPrivateCall = async (session, dispatch) => {
  sendMessage(session);
}

export const informModeratorSessionLeft = async (session) => {
  sendMessage(session, null, false);
}

export const unpublishAndDisconnectFromPrivateSession = (privateSession, privatePublisher, privateSubscriber, dispatch) => {

  // to put in tok (tok management)
  log(moduleName, "stop publishing in private");
  if (privatePublisher) {
    privateSession.unpublish(privatePublisher);
  }

  if (privateSubscriber) {
    log(moduleName, "stop subscribing in private");
    privateSession.unsubscribe(privateSubscriber);
  }

  unlistenFromPublisherEventsInPrivateSession(privatePublisher);
  disconnectFromSession(privateSession);

  dispatch({
    type: SESSIONS_ACTIONS.LEAVE_PRIVATE_SESSION,
    payload: { value: null },
  });
}

export const disconnectFromPublicSession = (publicSession, publicSubscriber, dispatch) => {
  // to put in tok (tok management)

  if (publicSession) {
    if (publicSubscriber) {
      log(moduleName, "stop subscribing in public");
      publicSession.unsubscribe(publicSubscriber);
    }

    unlistenFromViewerSessionEvents(publicSession);
    disconnectFromSession(publicSession);

  }

  dispatch({
    type: SESSIONS_ACTIONS.LEAVE_PUBLIC_SESSION,
    payload: { value: null },
  });
}
