import { error } from "../modules/logger";
import CONFIG from './environments'
import axios from 'axios';

const moduleName = "mod-sessions"

export const getSessions = async () => {
  try {
    const response = await axios.get(`${CONFIG.restUrl}/sessions`)
    if (response.status >= 200 && response.status <= 400 && response.data) {
      return response.data.data.sessions;
    } else {
      return [];
    }
  } catch (err) {
    error(moduleName, "can't GET /sessions", { error: err });
    return [];
  }
}

export const createOrGetModeratorRoom = async (token) => {
  try {
    const response = await axios.post(`${CONFIG.restUrl}/sessions`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    if (response.status >= 200 && response.status <= 400 && response.data) {
      return response.data.data.session;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, "can't POST /sessions", { error: err });
    return null;
  }
}

export const createTokenForModerator = async (sessionId, token) => {
  try {
    const response = await axios.get(`${CONFIG.restUrl}/sessions/${sessionId}/moderator`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    if (response.status >= 200 && response.status <= 400 && response.data) {
      return response.data.data.token;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, "can't Get /sessions/:id/moderator", { error: err });
    return null;
  }
}

export const createTokenForViewer = async (sessionId, token) => {
  try {
    const response = await axios.get(`${CONFIG.restUrl}/sessions/${sessionId}/subscriber`)
    if (response.status >= 200 && response.status <= 400 && response.data) {
      return response.data.data.token;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, "can't Get /sessions/:id/subscriber", { error: err });
    return null;
  }
}

export const createTokenForP2P = async (sessionId, token) => {

  try {
    let headers = null
    if (token) {
      headers = {
        'Authorization': `Bearer ${token}`
      }
    }

    const url = !!token ? `${CONFIG.restUrl}/sessions/${sessionId}/moderator/p2p` : `${CONFIG.restUrl}/sessions/${sessionId}/p2p`;

    const response = await axios.get(url, { headers })
    if (response.status >= 200 && response.status <= 400 && response.data) {
      return response.data.data.token;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, "can't Get /sessions/:id/p2p", { error: err });
    return null;
  }
}

