import { error, log } from "../modules/logger";
import { createOrGetModeratorRoom, createTokenForModerator, createTokenForP2P } from "../modules/sessions";
import { connectToSession, initSession, initPublisher, listenToPublisherEventsInPrivateSession, listenToModeratorSessionEvents, publishToSession, unpublishAndDisconnectFromPrivateSession, unpublishAndDisconnectFromPublicSession, sendMessage, pauseSession, listenToPrivateSessionEvents, listenToModeratorPublisherEvents, sendMessageSwitchToPrivate, unpublishFromSession } from "../modules/tok";
import { SESSIONS_ACTIONS } from "./listOfSessionsActions";

const moduleName = "moderatorSessionsActions";

export const MODERATOR_STATE = {
  IDLE: "IDLE",
  READY: "READY",
  ONERROR: "ONERROR",
  STREAMING_PUBLIC: "STREAMING_PUBLIC",
  PRIVATE_ASKED: "PRIVATE_ASKED",
  STREAMING_PRIVATE: "STREAMING_PRIVATE",
  PRIVATE_ENDED: "PRIVATE_ENDED",
  PAUSED: "PAUSED"
}

export const prepareSessionsForModerator = async (dispatch, user) => {
  log(moduleName, "execute action", { action: "prepare moderator sessions" });

  try {

    dispatch({
      type: SESSIONS_ACTIONS.PREPARE_SESSIONS_FOR_MODERATOR_IN_PROGRESS,
      payload: { value: {} },
    });

    const room = await createOrGetModeratorRoom(user.token);
    const publicToken = await createTokenForModerator(room.public.sessionId, user.token);
    if (!publicToken) {
      dispatch({
        type: SESSIONS_ACTIONS.PREPARE_SESSIONS_FOR_MODERATOR_FAILED,
        payload: { value: {} },
      });
      return;
    }

    const privateToken = await createTokenForP2P(room.private.sessionId, user.token);
    const publicSession = initSession(room.apiKey, room.public.sessionId);
    const privateSession = initSession(room.apiKey, room.private.sessionId);

    listenToModeratorSessionEvents(publicSession, dispatch);

    //Todo - Listen to P2P private session events
    listenToPrivateSessionEvents(privateSession, dispatch);

    await connectToSession(publicSession, publicToken);
    await connectToSession(privateSession, privateToken);

    dispatch({
      type: SESSIONS_ACTIONS.PREPARE_SESSIONS_FOR_MODERATOR_SUCCEEDED,
      payload: { value: { room, publicToken, privateToken, publicSession, privateSession } },
    });

  } catch (err) {
    dispatch({
      type: SESSIONS_ACTIONS.PREPARE_SESSIONS_FOR_MODERATOR_FAILED,
      payload: { value: {} },
    });
  }
}

export const publishToPublicSession = async (dispatch, videoElt, publicSession, name) => {
  log(moduleName, "execute action", { action: "publish to the public session" });

  try {
    log(moduleName, "create publisher for public session");
    const publicPublisher = await initPublisher(videoElt, name);

    if (!publicPublisher) {
      error(moduleName, "Can't init a publisher", {});
    }

    log(moduleName, "listen to publisher events");
    listenToModeratorPublisherEvents(publicPublisher, dispatch);

    log(moduleName, "publish to session");
    publishToSession(publicSession, publicPublisher);

    dispatch({
      type: SESSIONS_ACTIONS.SET_PUBLIC_PUBLISHER,
      payload: { value: { publicPublisher } },
    });

  } catch (err) {
    error(moduleName, "Can't publish to the public session", { err });
    dispatch({
      type: SESSIONS_ACTIONS.SET_PUBLIC_PUBLISHER_FAILED,
      payload: { value: null },
    });
  }
}

export const leavePrivateSession = async (privateSession, privatePublisher, privateSubscriber, dispatch) => {
  log(moduleName, "execute action", { action: "leave private session" });

  await unpublishAndDisconnectFromPrivateSession(privateSession, privatePublisher, privateSubscriber);

  dispatch({
    type: SESSIONS_ACTIONS.LEAVE_PRIVATE_SESSION,
    payload: { value: null },
  });
}

export const leavePublicSession = async (dispatch, publicSession, privateSession, publicPublisher) => {
  log(moduleName, "execute action", { action: "end sessions" });

  await unpublishAndDisconnectFromPublicSession(publicSession, privateSession, publicPublisher);

  dispatch({
    type: SESSIONS_ACTIONS.LEAVE_PUBLIC_SESSION,
    payload: { value: null },
  });

  dispatch({
    type: SESSIONS_ACTIONS.RESET,
    payload: { value: null },
  });
}

export const publishToPrivateSession = async (dispatch, videoElt, privateSession, name) => {
  log(moduleName, "execute action", { action: "publish to the private session" });

  try {
    const privatePublisher = await initPublisher(videoElt, name);

    if (!privatePublisher) {
      error(moduleName, "Can't init a publisher", {});
    }

    listenToPublisherEventsInPrivateSession(privatePublisher, privateSession, dispatch);

    publishToSession(privateSession, privatePublisher);

    dispatch({
      type: SESSIONS_ACTIONS.SET_PRIVATE_PUBLISHER,
      payload: { value: { privatePublisher } },
    });

  } catch (err) {
    error(moduleName, "Can't publish to the private session", { err });
    dispatch({
      type: SESSIONS_ACTIONS.SET_PRIVATE_PUBLISHER_FAILED,
      payload: { value: null },
    });
  }
}

export const mutePublicSession = async (publicPublisher) => {
  pauseSession(publicPublisher, false);
}

export const unmutePublicSession = async (publicPublisher) => {
  pauseSession(publicPublisher, true);
}

export const unpublishFromPublicSession = async (publicSession, publicPublisher) => {
  unpublishFromSession(publicSession, publicPublisher);
}

export const republishToPublicSession = async (publicSession, publicPublisher) => {
  publishToSession(publicSession, publicPublisher);
}

export const answerToPrivateUser = async (privateUser, publicSession) => {
  sendMessage(publicSession, privateUser)
}

export const informViewersSwitchToPrivate = async (publicSession) => {
  sendMessageSwitchToPrivate(publicSession, true);
}

export const informViewersSwitchToPublic = async (publicSession) => {
  sendMessageSwitchToPrivate(publicSession, false);
}

export const resetModeratorAfterError = async (dispatch) => {
  dispatch({
    type: SESSIONS_ACTIONS.RESET,
    payload: { value: null },
  });
}
