export const SESSIONS_ACTIONS = {
  GET_SESSIONS_IN_PROGRESS: "GET_SESSIONS_IN_PROGRESS",
  GET_SESSIONS_SUCCEEDED: "GET_SESSIONS_SUCCEEDED",
  GET_SESSIONS_FAILED: "GET_SESSIONS_FAILED",

  CREATE_OR_GET_SESSION_IN_PROGRESS: "CREATE_OR_GET_SESSION_IN_PROGRESS",
  CREATE_OR_GET_SESSION_SUCCEEDED: "CREATE_OR_GET_SESSION_SUCCEEDED",
  CREATE_OR_GET_SESSION_FAILED: "CREATE_OR_GET_SESSION_FAILED",

  CONNECT_TO_SESSION_IN_PROGRESS: "CONNECT_TO_SESSION_IN_PROGRESS",
  CONNECT_TO_SESSION_SUCCEEDED: "CONNECT_TO_SESSION_SUCCEEDED",
  CONNECT_TO_SESSION_FAILED: "CONNECT_TO_SESSION_FAILED",

  CONNECT_TO_PUBLIC_SESSION_SUCCEEDED: "CONNECT_TO_PUBLIC_SESSION_SUCCEEDED",
  CONNECT_TO_PUBLIC_SESSION_FAILED: "CONNECT_TO_PUBLIC_SESSION_FAILED",

  CONNECT_TO_PRIVATE_SESSION_SUCCEEDED: "CONNECT_TO_PRIVATE_SESSION_SUCCEEDED",
  CONNECT_TO_PRIVATE_SESSION_FAILED: "CONNECT_TO_PRIVATE_SESSION_FAILED",

  VIEWER_JOINED: "VIEWER_JOINED",
  VIEWER_LOST: "VIEWER_LOST",

  MODERATOR_LOST: "MODERATOR_LOST",

  UPDATE_STATE: "UPDATE_STATE",

  SET_PUBLIC_PUBLISHER: "SET_PUBLIC_PUBLISHER",
  SET_PUBLIC_PUBLISHER_FAILED: "SET_PUBLIC_PUBLISHER_FAILED",
  UNSET_PUBLIC_PUBLISHER: "UNSET_PUBLIC_PUBLISHER",

  SET_PRIVATE_PUBLISHER: "SET_PRIVATE_PUBLISHER",
  SET_PRIVATE_PUBLISHER_FAILED: "SET_PRIVATE_PUBLISHER_FAILED",
  UNSET_PRIVATE_PUBLISHER: "UNSET_PRIVATE_PUBLISHER",

  STREAM_AVAILABLE: "STREAM_AVAILABLE",
  STREAM_UNAVAILABLE: "STREAM_UNAVAILABLE",

  PRIVATE_STREAM_AVAILABLE: "PRIVATE_STREAM_AVAILABLE",
  PRIVATE_STREAM_UNAVAILABLE: "PRIVATE_STREAM_UNAVAILABLE",

  WATCH_STREAM_IN_PROGRESS: "WATCH_STREAM_IN_PROGRESS",
  WATCH_STREAM_SUCCEEDED: "WATCH_STREAM_SUCCEEDED",
  WATCH_STREAM_FAILED: "WATCH_STREAM_FAILED",

  WATCH_PRIVATE_STREAM_SUCCEEDED: "WATCH_PRIVATE_STREAM_SUCCEEDED",
  WATCH_PRIVATE_STREAM_FAILED: "WATCH_PRIVATE_STREAM_FAILED",

  P2P_SESSION_ASKED: "P2P_SESSION_ASKED",
  P2P_SESSION_ANSWERED: "P2P_SESSION_ANSWERED",
  P2P_SESSION_ENDED: "P2P_SESSION_ENDED",

  RESET: "RESET",
  RESET_PUBLISHER_COUNTER: "COUNTER",

  PREPARE_SESSIONS_FOR_MODERATOR_IN_PROGRESS: "PREPARE_SESSIONS_FOR_MODERATOR_IN_PROGRESS",
  PREPARE_SESSIONS_FOR_MODERATOR_SUCCEEDED: "PREPARE_SESSIONS_FOR_MODERATOR_SUCCEEDED",
  PREPARE_SESSIONS_FOR_MODERATOR_FAILED: "PREPARE_SESSIONS_FOR_MODERATOR_FAILED",

  LEAVE_PRIVATE_SESSION: "LEAVE_PRIVATE_SESSION",
  LEAVE_PUBLIC_SESSION: "LEAVE_PUBLIC_SESSION",

  MODERATOR_IN_PRIVATE: "MODERATOR_IN_PRIVATE",
  MODERATOR_OUT_PRIVATE: "MODERATOR_OUT_PRIVATE",
};