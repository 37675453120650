import { AUTH_ACTIONS } from "../actions/authActions";
import { SESSIONS_ACTIONS } from "../actions/listOfSessionsActions";
import { log } from "../modules/logger";
import { MODERATOR_STATE } from "../actions/moderatorSessionsActions";
import { VIEWER_STATE } from "../actions/sessionsActions";
import {CUSTOMIZATION_ACTIONS} from "../actions/listOfCustomizationActions";

const moduleName = "red-app";

const USER_TYPE = {
  VIEWER: "VIEWER",
  MODERATOR: "MODERATOR",
}

const initialAppState = {
  rooms: [],
  roomsGetInProgress: false,
  room: null,   // Room contains a public session and a private sessions
  publicSession: null,
  publicSessionError: false,
  privateSession: null,
  nbViewers: 0,
  connectToSessionInProgress: false,
  publicToken: null,
  privateToken: null,
  roomGetOrCreateInProgress: false,
  user: null,
  userGetInProgress: false,
  publicPublisher: null,
  privatePublisher: null,
  stream: null,
  privateStream: null,
  subscriber: null,
  privateSubscriber: null,
  watchInProgress: false,
  privateUser: null,
  moderatorState: MODERATOR_STATE.IDLE,
  viewerState: VIEWER_STATE.IDLE,
  prepareModeratorInProgress: false,
  userType: USER_TYPE.VIEWER,
  moderatorInPrivate: false,
  customizationInProgress: false,
  customization: null,
};

const reset = {
  rooms: [],
  roomsGetInProgress: false,
  room: null,
  publicSession: null,
  publicSessionError: false,
  privateSession: null,
  nbViewers: 0,
  connectToSessionInProgress: false,
  publicToken: null,
  privateToken: null,
  moderatorRoomCreateInProgress: false,
  publicPublisher: null,
  privatePublisher: null,
  stream: null,
  privateStream: null,
  subscriber: null,
  privateSubscriber: null,
  watchInProgress: false,
  privateUser: null,
  moderatorState: MODERATOR_STATE.IDLE,
  viewerState: VIEWER_STATE.IDLE,
  prepareModeratorInProgress: false,
  moderatorInPrivate: false
}

const appReducer = (state = initialAppState, action) => {

  log(moduleName, "reduce", { action: action.type, data: action.payload });

  switch (action.type) {

    case SESSIONS_ACTIONS.GET_SESSIONS_IN_PROGRESS:
      return { ...state, sessionsGetInProgress: true };

    case SESSIONS_ACTIONS.GET_SESSIONS_FAILED:
      return { ...state, rooms: [], sessionsGetInProgress: false };

    case SESSIONS_ACTIONS.GET_SESSIONS_SUCCEEDED:
      return { ...state, rooms: action.payload.value, sessionsGetInProgress: false };

    case AUTH_ACTIONS.AUTHENTICATE_IN_PROGRESS:
      return { ...state, userGetInProgress: true };

    case AUTH_ACTIONS.AUTHENTICATE_SUCCEEDED:
      return { ...state, user: action.payload.value, userGetInProgress: false, userType: USER_TYPE.MODERATOR };

    case AUTH_ACTIONS.AUTHENTICATE_FAILED:
      return { ...state, user: null, userGetInProgress: false };

    case AUTH_ACTIONS.SIGNOUT_SUCCEEDED:
      return { ...initialAppState };

    case SESSIONS_ACTIONS.CONNECT_TO_SESSION_IN_PROGRESS:
      return { ...state, connectToSessionInProgress: true, publicSessionError: false };

    case SESSIONS_ACTIONS.CONNECT_TO_SESSION_SUCCEEDED:
      return { ...state, publicSession: action.payload.value.publicSession, privateSession: action.payload.value.privateSession, connectToSessionInProgress: false };

    case SESSIONS_ACTIONS.CONNECT_TO_SESSION_FAILED:
      return { ...state, publicSession: null, privateSession: null, connectToSessionInProgress: false };

    case SESSIONS_ACTIONS.CONNECT_TO_PRIVATE_SESSION_SUCCEEDED:
      if (action.payload.value.privateToken) {
        return { ...state, privateSession: action.payload.value.privateSession, privateToken: action.payload.value.privateToken, viewerState: VIEWER_STATE.CONNECTED_PRIVATE, connectToSessionInProgress: false };
      } else {
        return { ...state, privateSession: action.payload.value.privateSession, connectToSessionInProgress: false };
      }

    case SESSIONS_ACTIONS.CONNECT_TO_PRIVATE_SESSION_FAILED:
      return { ...state, privateSession: null, connectToSessionInProgress: false };

    case SESSIONS_ACTIONS.CONNECT_TO_PUBLIC_SESSION_SUCCEEDED:
      if (action.payload.value.publicToken) {
        return { ...state, publicSession: action.payload.value.publicSession, publicToken: action.payload.value.publicToken, viewerState: VIEWER_STATE.CONNECTED_PUBLIC, connectToSessionInProgress: false, publicSessionError: false };
      } else {
        return { ...state, publicSession: action.payload.value.publicSession, connectToSessionInProgress: false, publicSessionError: false };
      }

    case SESSIONS_ACTIONS.CONNECT_TO_PUBLIC_SESSION_FAILED:
      return { ...state, publicSession: null, connectToSessionInProgress: false, publicSessionError: true };

    case SESSIONS_ACTIONS.VIEWER_JOINED:
      return { ...state, nbViewers: state.nbViewers + 1 };

    case SESSIONS_ACTIONS.VIEWER_LOST:
      return { ...state, nbViewers: state.nbViewers - 1 };

    case SESSIONS_ACTIONS.UPDATE_STATE:
      return { ...state, userState: action.payload.value };

    case SESSIONS_ACTIONS.UNSET_PUBLIC_PUBLISHER:
    case SESSIONS_ACTIONS.SET_PUBLIC_PUBLISHER_FAILED:
      return { ...state, publicPublisher: null };
    case SESSIONS_ACTIONS.SET_PUBLIC_PUBLISHER:
      return { ...state, publicPublisher: action.payload.value.publicPublisher, moderatorState: MODERATOR_STATE.STREAMING_PUBLIC };

    case SESSIONS_ACTIONS.UNSET_PRIVATE_PUBLISHER:
      return { ...state, privatePublisher: null };
    case SESSIONS_ACTIONS.SET_PRIVATE_PUBLISHER:
      if (state.userType === USER_TYPE.MODERATOR) {
        return { ...state, privatePublisher: action.payload.value.privatePublisher, moderatorState: MODERATOR_STATE.STREAMING_PRIVATE };
      } else {
        return { ...state, privatePublisher: action.payload.value.privatePublisher, viewerState: VIEWER_STATE.STREAMING_PRIVATE };
      }

    case SESSIONS_ACTIONS.STREAM_AVAILABLE:
      return { ...state, stream: action.payload.value };
    case SESSIONS_ACTIONS.STREAM_UNAVAILABLE:
      if (state.userType === USER_TYPE.MODERATOR) {
        return { ...state, stream: null };
      } else {
        return { ...state, stream: null, viewerState: VIEWER_STATE.LOST_PUBLIC };
      }

    case SESSIONS_ACTIONS.PRIVATE_STREAM_AVAILABLE:
      return { ...state, privateStream: action.payload.value };
    case SESSIONS_ACTIONS.PRIVATE_STREAM_UNAVAILABLE:
      if (state.userType === USER_TYPE.MODERATOR) {
        return { ...state, privateStream: null, moderatorState: MODERATOR_STATE.PRIVATE_ENDED };
      } else {
        return { ...state, privateStream: null, viewerState: VIEWER_STATE.LOST_PRIVATE };
      }

    case SESSIONS_ACTIONS.RESET: {
      return { ...state, ...reset }
    }

    case SESSIONS_ACTIONS.WATCH_STREAM_IN_PROGRESS:
      return { ...state, watchInProgress: true }
    case SESSIONS_ACTIONS.WATCH_STREAM_SUCCEEDED:
      return { ...state, watchInProgress: false, subscriber: action.payload.value.subscriber, viewerState: VIEWER_STATE.VIEWING_PUBLIC }
    case SESSIONS_ACTIONS.WATCH_STREAM_FAILED:
      return { ...state, watchInProgress: false, subscriber: null }

    case SESSIONS_ACTIONS.WATCH_PRIVATE_STREAM_SUCCEEDED:
      return { ...state, watchInProgress: false, privateSubscriber: action.payload.value.privateSubscriber, viewerState: VIEWER_STATE.VIEWING_PRIVATE }
    case SESSIONS_ACTIONS.WATCH_PRIVATE_STREAM_FAILED:
      return { ...state, watchInProgress: false, privateSubscriber: null }

    case SESSIONS_ACTIONS.P2P_SESSION_ASKED:
      if (state.moderatorState === MODERATOR_STATE.STREAMING_PUBLIC) {
        return { ...state, privateUser: action.payload.value.from, moderatorState: MODERATOR_STATE.PRIVATE_ASKED }
      } else {
        return { ...state }
      }

    case SESSIONS_ACTIONS.P2P_SESSION_ANSWERED:
      return { ...state, viewerState: VIEWER_STATE.PRIVATE_ANSWERED }

    case SESSIONS_ACTIONS.P2P_SESSION_ENDED:
      return { ...state, privateUser: null, moderatorState: MODERATOR_STATE.PRIVATE_ENDED }

    case SESSIONS_ACTIONS.PREPARE_SESSIONS_FOR_MODERATOR_IN_PROGRESS:
      return { ...state, prepareModeratorInProgress: true }

    case SESSIONS_ACTIONS.PREPARE_SESSIONS_FOR_MODERATOR_SUCCEEDED:
      return {
        ...state, prepareModeratorInProgress: false,
        publicSession: action.payload.value.publicSession,
        privateSession: action.payload.value.privateSession,
        room: action.payload.value.room,
        publicToken: action.payload.value.publicToken,
        privateToken: action.payload.value.privateToken,
        moderatorState: MODERATOR_STATE.READY
      }

    case SESSIONS_ACTIONS.PREPARE_SESSIONS_FOR_MODERATOR_FAILED:
      return {
        ...state, prepareModeratorInProgress: false,
        publicSession: null,
        privateSession: null,
        room: null,
        publicToken: null,
        privateToken: null,
        moderatorState: MODERATOR_STATE.ONERROR
      }

    case SESSIONS_ACTIONS.LEAVE_PRIVATE_SESSION:
      if (state.userType === USER_TYPE.MODERATOR) {
        return {
          ...state,
          privatePublisher: null,
          privateSubscriber: null,
          privateStream: null,
          moderatorState: MODERATOR_STATE.STREAMING_PUBLIC
        }
      } else {
        return {
          ...state,
          privatePublisher: null,
          privateSubscriber: null,
          privateStream: null,
          privateSession: null,
          privateToken: null
        }
      }

    case SESSIONS_ACTIONS.LEAVE_PUBLIC_SESSION:
      if (state.userType === USER_TYPE.MODERATOR) {
        return {
          ...state,
          publicPublisher: null,
          stream: null,
        }
      } else {
        return {
          ...state,
          subscriber: null,
          stream: null,
          publicSession: null,
          publicToken: null
        }
      }

    case SESSIONS_ACTIONS.MODERATOR_IN_PRIVATE:
      return { ...state, moderatorInPrivate: true }

    case SESSIONS_ACTIONS.MODERATOR_OUT_PRIVATE:
      return { ...state, moderatorInPrivate: false }

    case SESSIONS_ACTIONS.MODERATOR_LOST:
      return { ...state, moderatorInPrivate: false, viewerState: VIEWER_STATE.LOST_PUBLIC }

    case CUSTOMIZATION_ACTIONS.GET_CUSTOMIZATION_IN_PROGRESS:
      return { ...state, customizationInProgress: true}
    case CUSTOMIZATION_ACTIONS.GET_CUSTOMIZATION_SUCCEEDED:
      return { ...state, customizationInProgress: false, customization: action.payload.value}
    case CUSTOMIZATION_ACTIONS.GET_CUSTOMIZATION_FAILED:
      return { ...state, customizationInProgress: false, customization: null}
    default:
      return state;
  }
};

export { appReducer, initialAppState };
