import axios from "axios";
import CONFIG from "./environments";
import {error} from "./logger";

const moduleName = "customization";

export const getCustomization = async () => {
  try {
    const response = await axios.get(`${CONFIG.restUrl}/customization`)
    if (response.status >= 200 && response.status <= 400 && response.data) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, "can't GET /customization", { error: err });
    return null;
  }
}
