import { error } from "../modules/logger";
import CONFIG from './environments'
import axios from 'axios';

const moduleName = "mod-auth"

export const authenticate = async (username, password) => {
  try {
    const response = await axios.post(`${CONFIG.restUrl}/login`, { username, password });
    if (response.status >= 200 && response.status <= 400 && response.data) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    error(moduleName, "can't GET /sessions", { error: err });
    return null;
  }
}
