import { Menu, Icon } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import { useContext } from 'react';
import AppContext from "../../contexts/appContext";
import './Topbar.css';
import { signOut } from '../../actions/authActions';

function Topbar({ dispatch }) {

  const history = useHistory();
  const appState = useContext(AppContext);

  const onSignin = () => {
    history.push("/signin");
  }

  const onSignout = async () => {
    await signOut(dispatch);
  }

  const onModerator = async () => {
    history.push(`/moderator/${appState.user.id}`)
  }

  const onHome = () => {
    history.push("/");
  }

  const TOPBAR_TEXT = {
    SIGNIN: "Sign-In",
    SIGNOUT: "Sign-Out"
  }

  return (
    <Menu inverted fixed="top" pointing fluid size='mini'>
      <Menu.Item>
        <Icon name='home' onClick={onHome} />
      </Menu.Item>
      <Menu.Menu position='right'>
        {!appState.user && (
        <Menu.Item
            name={TOPBAR_TEXT.SIGNIN}
          onClick={onSignin}
        >
        </Menu.Item>
        )}
        {appState.user && (
          <>
            <Menu.Item header onClick={onModerator}><Icon name="user" /> {appState.user.username}</Menu.Item>
            <Menu.Item
              name={TOPBAR_TEXT.SIGNOUT}
              onClick={onSignout}
            >
            </Menu.Item>
          </>
        )}
      </Menu.Menu>
    </Menu>
  );
}

export default Topbar;
