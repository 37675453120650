import {error, log} from "../modules/logger";
import {CUSTOMIZATION_ACTIONS} from "./listOfCustomizationActions";
import {getCustomization} from "../modules/customization";

const moduleName = "customizationActions";

export const getCustomizationMedia = async (dispatch) => {
  log(moduleName, "execute action", { action: "get customization media" });

  dispatch({
    type: CUSTOMIZATION_ACTIONS.GET_CUSTOMIZATION_IN_PROGRESS,
    payload: { value: null },
  });

  try {
    const data = await getCustomization();

    dispatch({
      type: CUSTOMIZATION_ACTIONS.GET_CUSTOMIZATION_SUCCEEDED,
      payload: { value: data },
    });
  } catch (err) {
    error(moduleName, "Can't get sessions", { err });
    dispatch({
      type: CUSTOMIZATION_ACTIONS.GET_CUSTOMIZATION_FAILED,
      payload: { value: [] },
    });
  }
};
