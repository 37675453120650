import { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { Grid, Form, Icon, Button, Header } from 'semantic-ui-react'
import { signIn } from '../../actions/authActions';
import { disconnectFromPublicSession } from '../../actions/sessionsActions';
import AppContext from "../../contexts/appContext";
import './Signin.css';

function Signin({ dispatch }) {

  const history = useHistory();

  const appState = useContext(AppContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (appState.user) {
      history.push(`/moderator/${appState.user.id}`)
    }
  }, [appState.user, history])

  useEffect(() => {
    disconnectFromPublicSession(appState.publicSession, appState.publicSubscriber, dispatch);
  }, []);

  const onSignIn = async () => {
    await signIn(email, password, dispatch)
  }

  const SIGNIN_TEXT = {
    TITLE: "Digital Shopdoor",
    SUBTITLE: "Sign-in to your account",
    SIGNIN: "SIGN-IN"
  };

  return (
    <div className="Signin">
      <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h2' color='blue' textAlign='center' icon>
            <Icon name='video' className="SigninIconColor" />
            <Header.Content className="SigninTitle">{SIGNIN_TEXT.TITLE}
              <Header.Subheader className="SigninSubElementColor">{SIGNIN_TEXT.SUBTITLE}</Header.Subheader>
            </Header.Content>
          </Header>

          <Form size='large' className="SigninForm">
            
            <Form.Input className="SigninInput" 
              fluid
              icon='user'
              iconPosition='left'
              placeholder='Name'
              value={email}
              required 
              onChange={(e, { value }) => setEmail(value)}
            />

            <Form.Input className="SigninInput"
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              value={password}
              type='password'
              required
              name="password"
              onChange={(e, { value }) => setPassword(value)}
            />

            <Button className="SigninButton" fluid size='large' onClick={onSignIn}>
              {SIGNIN_TEXT.SIGNIN}
            </Button>
          </Form>

        </Grid.Column>
      </Grid>

    </div>
  );
}

export default Signin;
