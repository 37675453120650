import OT from "@opentok/client";
import { accessAllowed, accessDenied, accessDialogOpened, accessDialogClosed, connectionCreated, connectionDestroyed, sessionConnected, sessionDisconnected, streamCreated, streamDestroyed, mediaStopped, privateCallAsked, privateCallAnswered, privateStreamCreated, privateStreamDestroyed, publicStreamCreatedFromModerator, publicStreamDestroyedFromModerator, publicStreamCreated, publicStreamDestroyed, inPrivateReceived, outPrivateReceived, privateCallEnded, publicConnectionDestroyed } from "./helper";
import { log, error } from "./logger";

const moduleName = "tok";

let dispatcher = null;
let publicSession = null;
let privateSession = null;
let moderatorId = null;

/**
 * Listeners 
 */

// Listen to public stream from visitor
const onPublicStreamCreated = (event) => publicStreamCreated(event, publicSession, dispatcher);
const onPublicStreamDestroyed = (event) => publicStreamDestroyed(event, publicSession, dispatcher);

//Listen to public stream published from moderator
const onPublicStreamCreatedFromModerator = (event) => publicStreamCreatedFromModerator(event, dispatcher);
const onPublicStreamDestroyedFromModerator = (event) => publicStreamDestroyedFromModerator(event, dispatcher)

// Listen to stream created by the user (moderator/visitor) in private session
const onSelfStreamCreated = (event) => streamCreated(event, dispatcher);
const onSelfStreamDestroyed = (event) => streamDestroyed(event, dispatcher);

// Listen to published stream in private session
const onPrivateStreamCreated = (event) => privateStreamCreated(event, privateSession, dispatcher);
const onPrivateStreamDestroyed = (event) => privateStreamDestroyed(event, privateSession, dispatcher);

// Listen to public connection destroyed in public session (visitor --> Detect moderator left)
const onConnectionDestroyedFromPublic = (event) => publicConnectionDestroyed(event, moderatorId, dispatcher);

const onConnectionCreated = (event) => connectionCreated(event, moderatorId, dispatcher);
const onConnectionDestroyed = (event) => connectionDestroyed(event, moderatorId, dispatcher);

const onPrivateCallAsked = (event) => privateCallAsked(event, dispatcher);
const onPrivateCallAnswered = (event) => privateCallAnswered(event, dispatcher);
const onPrivateCallEnded = (event) => privateCallEnded(event, dispatcher);

const onInPrivateReceived = (event) => inPrivateReceived(event, dispatcher);
const onOutPrivateReceived = (event) => outPrivateReceived(event, dispatcher);

export const isCompatible = () => {
  return (OT.checkSystemRequirements() === 1);
}

export const initSession = (apiKey, sessionId) => {
  // Init the log level
  OT.setLogLevel(OT.ERROR);

  if (!isCompatible()) {
    return;
  }

  return (OT.initSession(apiKey, sessionId));
}

export const connectToSession = (session, token) => {
  return new Promise((resolve, reject) => {

    session.connect(token, (err) => {
      if (err) {
        error(moduleName, "Can't connect to session", { err });
        reject(null);
      } else {
        log(moduleName, "Connected to session", { sessionId: session.sessionId });
        resolve(session);
      }
    })
  })
}

export const disconnectFromSession = (session) => {
  if (!session) {
    error(moduleName, "Can't disconnect from session - No session");
    return;
  }

  log(moduleName, "disconnect from session");
  session.disconnect();
}

export const listenToPrivateSessionEvents = (session, dispatch) => {
  dispatcher = dispatch;
  privateSession = session;

  if (!session) {
    error(moduleName, "Can't listen to private session event - no session");
    return;
  }

  session.on("sessionConnected", sessionConnected);
  session.on("sessionDisconnected", sessionDisconnected);
  session.on("streamCreated", onPrivateStreamCreated);
  session.on("streamDestroyed", onPrivateStreamDestroyed);
}

export const unlistenFromPrivateSessionEvents = (session) => {
  if (!session) {
    error(moduleName, "Can't unlisten to private session event - no session");
    return;
  }

  session.off("sessionConnected", sessionConnected);
  session.off("sessionDisconnected", sessionDisconnected);
  session.off("streamCreated", onPrivateStreamCreated);
  session.off("streamDestroyed", onPrivateStreamDestroyed);
}

export const listenToModeratorSessionEvents = (session, dispatch) => {
  dispatcher = dispatch;
  if (!session) {
    error(moduleName, "Can't listen to session event - no session");
    return;
  }

  session.on("sessionConnected", sessionConnected);
  session.on("sessionDisconnected", sessionDisconnected);
  session.on("connectionCreated", onConnectionCreated);
  session.on("connectionDestroyed", onConnectionDestroyed);
  session.on("signal:p2p", onPrivateCallAsked);
  session.on("signal:endp2p", onPrivateCallEnded);
}

export const unlistenFromModeratorSessionEvents = (session) => {
  if (!session) {
    error(moduleName, "Can't unlisten from session event - no session");
    return;
  }

  session.off("sessionConnected", sessionConnected);
  session.off("sessionDisconnected", sessionDisconnected);
  session.off("connectionCreated", onConnectionCreated);
  session.off("connectionDestroyed", onConnectionDestroyed);
  session.off("signal:p2p", onPrivateCallAsked);
  session.off("signal:endp2p", onPrivateCallEnded);
}

export const listenToViewerSessionEvents = (session, userId, dispatch) => {
  dispatcher = dispatch;
  publicSession = session;
  moderatorId = userId;

  if (!session) {
    error(moduleName, "Can't listen to session event - no session");
    return;
  }

  session.on("sessionConnected", sessionConnected);
  session.on("sessionDisconnected", sessionDisconnected);
  session.on("connectionDestroyed", onConnectionDestroyedFromPublic);
  session.on("streamCreated", onPublicStreamCreated);
  session.on("streamDestroyed", onPublicStreamDestroyed);
  session.on("signal:accept", onPrivateCallAnswered);
  session.on("signal:inprivate", onInPrivateReceived);
  session.on("signal:outprivate", onOutPrivateReceived);
}

export const unlistenFromViewerSessionEvents = (session) => {
  if (!session) {
    error(moduleName, "Can't unlisten from session event - no session");
    return;
  }

  session.off("sessionConnected", sessionConnected);
  session.off("sessionDisconnected", sessionDisconnected);
  session.off("streamCreated", onPublicStreamCreated);
  session.off("streamDestroyed", onPublicStreamDestroyed);
  session.off("signal:accept", onPrivateCallAnswered);
  session.off("signal:inprivate", onInPrivateReceived);
  session.off("signal:outprivate", onOutPrivateReceived);
}

export const initPublisher = async (DOMElt, name) => {

  log(moduleName, "init publisher", { name });

  const publisherProperties = {
    resolution: '640x480',
    width: "100%",
    height: "100%",
    name,
    insertMode: 'append'
  };

  return new Promise((resolve, reject) => {
    try {

      if (!isCompatible()) {
        error(moduleName, "Can't create publisher - not compliant");
        reject(null);
        return;
      }

      const publisher = OT.initPublisher(DOMElt, publisherProperties, (error) => {
        if (error) {
          error(moduleName, "Can't create publisher", { error });
          reject(null);
          return;
        }
        resolve(publisher);
      })
    } catch (err) {
      error(moduleName, "Can't create publisher", { err });
      reject(null);
    }
  });
}

export const listenToModeratorPublisherEvents = (publisher, dispatch) => {
  dispatcher = dispatch;

  if (!publisher) {
    error(moduleName, "Can't listen to publisher event - no publisher");
    return;
  }

  publisher.on("accessAllowed", accessAllowed);
  publisher.on("accessDenied", accessDenied);
  publisher.on("accessDialogOpened", accessDialogOpened);
  publisher.on("accessDialogClosed", accessDialogClosed);
  publisher.on("mediaStopped", mediaStopped);
  publisher.on("streamCreated", onPublicStreamCreatedFromModerator);
  publisher.on("streamDestroyed", onPublicStreamDestroyedFromModerator);
}

export const unlistenFromModeratorPublisherEvents = (publisher) => {
  if (!publisher) {
    error(moduleName, "Can't unlisten to publisher event - no publisher");
    return;
  }

  publisher.off("accessAllowed", accessAllowed);
  publisher.off("accessDenied", accessDenied);
  publisher.off("accessDialogOpened", accessDialogOpened);
  publisher.off("accessDialogClosed", accessDialogClosed);
  publisher.off("mediaStopped", mediaStopped);
  publisher.off("streamCreated", onPublicStreamCreatedFromModerator);
  publisher.off("streamDestroyed", onPublicStreamDestroyedFromModerator);
}

export const listenToPublisherEventsInPrivateSession = (publisher, session, dispatch) => {
  dispatcher = dispatch;
  privateSession = session;

  if (!publisher) {
    error(moduleName, "Can't listen to publisher event - no publisher");
    return;
  }

  publisher.on("accessAllowed", accessAllowed);
  publisher.on("accessDenied", accessDenied);
  publisher.on("accessDialogOpened", accessDialogOpened);
  publisher.on("accessDialogClosed", accessDialogClosed);
  publisher.on("mediaStopped", mediaStopped);
  publisher.on("streamCreated", onSelfStreamCreated);
  publisher.on("streamDestroyed", onSelfStreamDestroyed);
}

export const unlistenFromPublisherEventsInPrivateSession = (publisher) => {
  if (!publisher) {
    error(moduleName, "Can't unlisten to publisher event - no publisher");
    return;
  }

  publisher.off("accessAllowed", accessAllowed);
  publisher.off("accessDenied", accessDenied);
  publisher.off("accessDialogOpened", accessDialogOpened);
  publisher.off("accessDialogClosed", accessDialogClosed);
  publisher.off("mediaStopped", mediaStopped);
  publisher.off("streamCreated", onSelfStreamCreated);
  publisher.off("streamDestroyed", onSelfStreamDestroyed);
}


export const publishToSession = (session, publisher) => {
  if (!session) {
    error(moduleName, "Can't publish - no session");
    return null;
  }

  if (!publisher) {
    error(moduleName, "Can't publish - no publisher");
    return null;
  }

  if (session.capabilities.publish !== 1) {
    error(moduleName, "Can't publish - no right");
    return null;
  }

  log(moduleName, "publish to session");
  session.publish(publisher);
}

export const unpublishFromSession = (session, publisher) => {
  if (!session) {
    error(moduleName, "Can't unpublish - no session");
    return null;
  }

  if (!publisher) {
    error(moduleName, "Can't unpublish - no publisher");
    return null;
  }

  log(moduleName, "unlisten from publisher");
  unlistenFromModeratorPublisherEvents(publisher);

  log(moduleName, "unpublish from session");
  session.unpublish(publisher);
}

export const unpublishAndDisconnectFromPrivateSession = (privateSession, privatePublisher, privateSubscriber) => {
  log(moduleName, "stop publishing in private");
  if (privatePublisher) {
    privateSession.unpublish(privatePublisher);
  }
  if (privateSubscriber) {
    log(moduleName, "stop unscribing in private");
    privateSession.unsubscribe(privateSubscriber);
  }
  unlistenFromPublisherEventsInPrivateSession(privatePublisher);
}

export const unpublishAndDisconnectFromPublicSession = (publicSession, privateSession, publicPublisher) => {
  if (publicSession) {
    unlistenFromModeratorSessionEvents(publicSession);
  }

  if (privateSession) {
    unlistenFromPrivateSessionEvents(privateSession);
    privateSession.disconnect();
  }
  if (publicPublisher) {
    unlistenFromModeratorPublisherEvents(publicPublisher);
  }

  if (publicSession) {
    publicSession.unpublish(publicPublisher);
    publicSession.disconnect();
  }
}

export const createSubscriber = async (session, stream, videoElt, withAudio = false) => {
  const viewerProperties = {
    width: "100%",
    height: "100%",
    insertMode: 'append',
    subscribeToVideo: true,
    subscribeToAudio: withAudio,
  };

  return new Promise((resolve, reject) => {
    const subscriber = session.subscribe(stream, videoElt, viewerProperties, (error) => {
      if (error) {
        error(moduleName, "Can't watch - error", { error });
        reject(null);
      } else {
        log(moduleName, "Watching stream");
        resolve(subscriber);
      }
    });
  });
}

export const sendMessage = async (session, recipient, toStart = true) => {
  if (recipient) {
    session.signal({
      type: "accept",
      to: recipient,
      data: ""
    }, (err) => {
      if (err) {
        error(moduleName, "Can't answer a signal to viewer - error", { err });
      } else {
        log(moduleName, "Sending answer signal");
      }
    })
  } else {
    session.signal({
      type: toStart ? "p2p" : "endp2p",
      data: ""
    }, (err) => {
      if (err) {
        error(moduleName, "Can't send a signal - error", { err });
      } else {
        log(moduleName, "Sending signal");
      }
    })
  }
}

export const pauseSession = async (publisher, isPlaying = false) => {
  publisher.publishVideo(isPlaying);
  publisher.publishAudio(isPlaying);
}

export const sendMessageSwitchToPrivate = async (publicSession, isInPrivate) => {

  publicSession.signal({
    type: isInPrivate ? "inprivate" : "outprivate",
    data: "",
  }, (err) => {
    if (err) {
      error(moduleName, "Can't inform viewers - error", { err });
    } else {
      log(moduleName, `Inform viewers of switching to ${isInPrivate ? "private session" : "public session"}`);
    }
  })
}

export const setModeratorID = (id) => {
  log(moduleName, `Set moderator id to ${id}`);
  moderatorId = id;
}
