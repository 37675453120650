import React from 'react';
import ReactDOM from 'react-dom';
//import adapter from "webrtc-adapter";
import reportWebVitals from './reportWebVitals';

import 'semantic-ui-css/semantic.min.css';
import "./resources/variables.css";
import "./resources/crocks.css";
import "./resources/global.css";

import "./modules/i18n";
import { Debugout } from "debugout.js";
import { log, setReporter } from "./modules/logger";
import CONFIG from "./modules/environments";

import App from "./components/App/App";

import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const bugout = new Debugout({
  realTimeLoggingOn: false,
  useTimestamps: true,
  useLocalStorage: true,
  includeSessionMetadata: true,
  logFilename: `shopdoo-${Date.now()}.log`,
  quoteStrings: true,
});

setReporter(bugout);

log("index", "application started");

log("index", "environment", { mode: CONFIG.mode });

//log("index", "adapter browser", { browser: adapter.browserDetails.browser, version: adapter.browserDetails.version });
