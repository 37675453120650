import { useState, useEffect, useContext, useRef } from 'react';
import { Button, Segment, Icon, Header, Statistic } from 'semantic-ui-react'
import AppContext from "../../contexts/appContext";
import { log, warning } from "../../modules/logger";
import { publishToPublicSession, leavePublicSession, prepareSessionsForModerator, MODERATOR_STATE, publishToPrivateSession, answerToPrivateUser, leavePrivateSession, informViewersSwitchToPrivate, informViewersSwitchToPublic, unpublishFromPublicSession, resetModeratorAfterError } from '../../actions/moderatorSessionsActions';
import './Moderator.css';
import { subscribeToPrivateStreamInSession } from '../../actions/sessionsActions';
import NewViewerSound from "./soundjaybeep-07a.mp3";

const moduleName = "scr-moderator"

const SCREEN_STATE = {
  IDLE: "idle",
  PREPARE_PUBLIC: "prepare_public",
  IN_PUBLIC_SESSION: "public",
  BACK_IN_PUBLIC_SESSION: "back_public",
  IN_PRIVATE_SESSION: "private",
}

const STATE = {
  NO_STREAM: "Video ist gestoppt",          // "BackStage",
  PUBLIC: "Video läuft",                    // "Public live",
  PRIVATE: "Privatgespräch"                 // "Private live"
}

const MODERATOR_TEXT = {
  READY_STREAM: "Sie können den Live Video Stream jetzt starten",                     // "Ready to stream?",
  STREAM_DEFAULT: "Auf START klicken | bei Nachfrage Kamera und Mikrofon freigeben",  // "Click on the STREAM LIVE button to start your live streaming",
  STREAM_LIVE: "Start",                                                               // "Stream live",
  STREAM_STOP: "Stop"                                                                 // "End Live"
}

const displayState = (state) => {
  if (state === SCREEN_STATE.IDLE) {
    return STATE.NO_STREAM;
  } else if (state === SCREEN_STATE.IN_PRIVATE_SESSION) {
    return STATE.PRIVATE;
  }
  return STATE.PUBLIC
}

const isInPublicSession = (state) => (state === SCREEN_STATE.PREPARE_PUBLIC || state === SCREEN_STATE.IN_PUBLIC_SESSION || state === SCREEN_STATE.BACK_IN_PUBLIC_SESSION);

const isInPrivateSession = (state) => (state === SCREEN_STATE.IN_PRIVATE_SESSION);

const isIdle = (state) => (state === SCREEN_STATE.IDLE);

const changeToState = (currentState, newState, stateFunction) => {
  if (currentState !== newState) {
    stateFunction(newState);
  } else {
    warning(moduleName, `already in state ${newState} - do nothing`);
  }
}

function Moderator({ dispatch }) {

  const [screenState, setScreenState] = useState(SCREEN_STATE.IDLE);

  //const { id } = useParams();

  const appState = useContext(AppContext);

  const moderatorPublicVideoRef = useRef(null);
  const moderatorPrivateVideoRef = useRef(null);
  const moderatorViewerRef = useRef(null);

  useEffect(() => {
    log(moduleName, `Moderator state changed to ${appState.moderatorState}`);
    if (appState.moderatorState === MODERATOR_STATE.READY) {
      changeToState(screenState, SCREEN_STATE.IN_PUBLIC_SESSION, setScreenState);
    } else if (appState.moderatorState === MODERATOR_STATE.PRIVATE_ASKED) {
      answerToPrivateUser(appState.privateUser, appState.publicSession);
      changeToState(screenState, SCREEN_STATE.IN_PRIVATE_SESSION, setScreenState);
    } else if (appState.moderatorState === MODERATOR_STATE.PRIVATE_ENDED) {
      changeToState(screenState, SCREEN_STATE.BACK_IN_PUBLIC_SESSION, setScreenState);
    } else if (appState.moderatorState === MODERATOR_STATE.ONERROR) {
      resetModeratorAfterError(dispatch);
      changeToState(screenState, SCREEN_STATE.IDLE, setScreenState);
    }
  }, [appState.moderatorState])

  useEffect(() => {
    log(moduleName, `Screen state changed to ${screenState}`);
    if (screenState === SCREEN_STATE.PREPARE_PUBLIC) {
      log(moduleName, `prepare sessions for moderator`);
      prepareSessionsForModerator(dispatch, appState.user);
    } else if (screenState === SCREEN_STATE.IN_PUBLIC_SESSION) {
      log(moduleName, `publish to public session`);
      publishToPublicSession(dispatch, moderatorPublicVideoRef.current, appState.publicSession, "Live")
    } else if (screenState === SCREEN_STATE.IN_PRIVATE_SESSION) {
      log(moduleName, `unpublish public session`);
      unpublishFromPublicSession(appState.publicSession, appState.publicPublisher);
      informViewersSwitchToPrivate(appState.publicSession);
      log(moduleName, `publish to private session`);
      publishToPrivateSession(dispatch, moderatorPrivateVideoRef.current, appState.privateSession, appState.user.username);
    } else if (screenState === SCREEN_STATE.BACK_IN_PUBLIC_SESSION) {
      log(moduleName, `leave private session`);
      leavePrivateSession(appState.privateSession, appState.privatePublisher, appState.privateSubscriber, dispatch);
      log(moduleName, `inform viewers session`);
      informViewersSwitchToPublic(appState.publicSession);
      log(moduleName, `publish public session`);
      publishToPublicSession(dispatch, moderatorPublicVideoRef.current, appState.publicSession, "Live")
    }
  }, [screenState])

  useEffect(() => {
    // Detect viewer stream in private session
    const stream = appState.privateStream;
    if (stream && !stream.destroyed && isInPrivateSession(screenState)) {
      log(moduleName, `subscribe to stream in private session`);
      subscribeToPrivateStreamInSession(appState.privateSession, stream, moderatorViewerRef.current, dispatch);
    } else if (stream && stream.destroyed && isInPrivateSession(screenState)) {
      log(moduleName, `back to public session`);
      changeToState(screenState, SCREEN_STATE.BACK_IN_PUBLIC_SESSION, setScreenState);
    }
  }, [appState.privateStream, screenState])

  const onStartSession = () => {
    log(moduleName, "[CLICK] start publics session");
    changeToState(screenState, SCREEN_STATE.PREPARE_PUBLIC, setScreenState);
  }

  const onStopSession = () => {
    log(moduleName, "[CLICK] stop public session");
    log(moduleName, `leave public session`);
    leavePublicSession(dispatch, appState.publicSession, appState.privateSession, appState.publicPublisher);
    changeToState(screenState, SCREEN_STATE.IDLE, setScreenState);
  }

  const onLeavePrivateSession = () => {
    log(moduleName, "[CLICK] stop private session");
    log(moduleName, `leave private session`);
    leavePrivateSession(appState.privateSession, appState.privatePublisher, appState.privateSubscriber, dispatch);
    changeToState(screenState, SCREEN_STATE.BACK_IN_PUBLIC_SESSION, setScreenState);
  }

  // const onGoPrivate = () => {
  //   changeToState(screenState, SCREEN_STATE.IN_PRIVATE_SESSION, setScreenState);
  // }

  return (
    <div className="Moderator">
      <div className="moderatorVideos">
        {!isIdle(screenState) && (
          <>

            {isInPrivateSession(screenState) && (
              <div className="moderatorPrivateAreaVisible">
                <div ref={moderatorViewerRef} id="viewerStream" className="subscriberVideo subscriberVideo-height">
                  <div className="moderatorCounterArea">
                    <div className="moderatorCounter"><Icon name="user"></Icon>{appState.nbViewers}</div>
                  </div>
                </div>
                <div ref={moderatorPrivateVideoRef} id="privateStream" className="moderatorVideo moderatorVideo-height">
                  <div className="moderatorEndButton">
                    <Button circular size="huge" icon="stop" color="red" onClick={onLeavePrivateSession} className="moderatorNextButton" disabled={isIdle(screenState)} />
                  </div>
                </div>
              </div>
            )}

            <div className={isInPublicSession(screenState) ? "moderatorPublicAreaVisible" : "moderatorPublicAreaHidden"}>
              <div className={ appState.nbViewers > 0 ? "subscriberCounterColored subscriberCounter-height" : "subscriberCounterBlack subscriberCounter-height" } >
                {appState.nbViewers > 0 ? <audio autoplay='true' src={NewViewerSound}> </audio> : null }
                <div className="subscriberCounter-area">
                  <Statistic size='huge' inverted label={'Visitors'} value={appState.nbViewers} />
                </div>
              </div>
              <div ref={moderatorPublicVideoRef} id="publicStream" className="moderatorVideo moderatorVideo-height"></div>
            </div>

          </>
        )}

        {isIdle(screenState) && (
          <div className="moderatorNoStream">
            <Segment placeholder>
              <Header icon>
                <Icon name='video' className="moderatorNoStreamIcon" />
                <Header.Content className="moderatorNoStreamTitle">{MODERATOR_TEXT.READY_STREAM}</Header.Content>
                <Header.Subheader className="moderatorNoStreamSubTitle">{MODERATOR_TEXT.STREAM_DEFAULT}</Header.Subheader>
              </Header>
            </Segment>
          </div>
        )}
      </div>

      <div className="moderatorControls">
        <span className="moderatorControlsState">{displayState(screenState)}</span>
        <span className="moderatorControlsEmpty"></span>
        {isIdle(screenState) && (
          <Button color="green" compact="true" onClick={onStartSession} className="moderatorStartButton">{MODERATOR_TEXT.STREAM_LIVE}</Button>
        )}
        {/* {!isIdle(screenState) && (
          <Button color="red" onClick={onGoPrivate} className="moderatorEndLiveButton">Private</Button>
        )} */}
        {!isIdle(screenState) && (
          <Button color="red" compact="true" onClick={onStopSession} className="moderatorEndLiveButton">{MODERATOR_TEXT.STREAM_STOP}</Button>
        )}
      </div>

    </div>
  );
}

export default Moderator;
