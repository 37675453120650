import { Header, Segment, Icon } from 'semantic-ui-react'
import CONFIG from '../../modules/environments'
import './NoStream.css';
import {useEffect, useState, useContext} from "react";
import AppContext from "../../contexts/appContext";

function NoStream({height, width, reason}) {

  const [noStreamMedia, setNoStreamMedia] = useState(null);
  const [noStreamType, setNoStreamType] = useState("video");
  const [privateCallMedia, setPrivateCallMedia] = useState(null);
  const [privateCallType, setPrivateCallType] = useState("video");

  const appState = useContext(AppContext);

  useEffect(() => {
    if(appState.customization) {
      // Media when no stream
      const noStreamMedia = appState.customization["noStreamMedia"];
      if(noStreamMedia.startsWith("youtube:")) {
        setNoStreamMedia(noStreamMedia.substring(8));
        setNoStreamType("video");
      } else if (noStreamMedia.startsWith("image:")) {
        setNoStreamMedia(noStreamMedia.substring(6));
        setNoStreamType("image");
      }

      // Media when someone is in private call
      const privateCallMedia = appState.customization["privateCallMedia"];
      if(privateCallMedia.startsWith("youtube:")) {
        setPrivateCallMedia(privateCallMedia.substring(8));
        setPrivateCallType("video");
      } else if (privateCallMedia.startsWith("image:")) {
        setPrivateCallMedia(privateCallMedia.substring(6));
        setPrivateCallType("image");
      }
    }
  }, [appState.customization])


// display placeholder when there are no media available
// display iframe when the  media is 'video'
// display image if the media is 'image'
// removed the fixed width/height and added noStream-video class to control it via css - KK
  return (
    <div className="nostream">
      {(!noStreamMedia && !privateCallMedia) && (
      <Segment placeholder className="noStream-text">
        <Header icon>
          <Icon name='eye slash' />
          Zur Zeit läuft keine Übertragung.
          <Header.Subheader>Schauen Sie doch später wieder vorbei.</Header.Subheader>
          <Header.Subheader>Ich freue mich auf Sie.</Header.Subheader>
         </Header>
      </Segment>
      )}
      {((noStreamMedia && noStreamType === "video" && reason === "nostream") || (privateCallMedia && privateCallType === "video" && reason === "noprivate")) && (
        <iframe
          id="player"
          type="text/html"
          className="noStream-video"
          src= { reason === "nostream" ? `${noStreamMedia}?autoplay=0&mute=0` : `${privateCallMedia}?autoplay=1&mute=0` }
          frameBorder="0" />
      )}
      {((noStreamMedia && noStreamType === "image" && reason === "nostream") || (privateCallMedia && privateCallType === "image" && reason === "noprivate")) && (
        <img className="noStream-image" src={ reason === "nostream" ? `${CONFIG.restUrl}/${noStreamMedia}` : `${CONFIG.restUrl}/${privateCallMedia}` } />
      )}
    </div>
  );
}

export default NoStream;
