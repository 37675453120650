import {useEffect, useReducer} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import AppContext from "../../contexts/appContext";
import { appReducer, initialAppState } from "../../reducers/appReducer";

import EmbeddedView from '../EmbeddedView/EmbeddedView';
import Viewer from '../Viewer/Viewer';
import Topbar from "../Topbar/Topbar";
import Signin from "../Signin/Signin";
import Moderator from "../Moderator/Moderator";

import './App.css';
import {getCustomizationMedia} from "../../actions/customizationActions";

function App() {

  const [appState, dispatch] = useReducer(appReducer, initialAppState);

  useEffect(() => {
    getCustomizationMedia(dispatch).then(()=> {
      console.log("got customization");
    });
  }, [])

  return (
    <AppContext.Provider value={appState} >
      <Router>
        <Switch>
          <Route exact path="/" >
            <Topbar dispatch={dispatch} />
            <Viewer dispatch={dispatch} />
          </Route>
          <Route exact path="/signin">
            <Topbar dispatch={dispatch} />
            <Signin dispatch={dispatch} />
          </Route>
          <Route path="/moderator/:id">
            <Topbar dispatch={dispatch} />
            {appState.user ? <Moderator dispatch={dispatch} /> : <Redirect to="/signin" />}
          </Route>
          <Route exact path="/embeddedview">
            <EmbeddedView dispatch={dispatch} />
          </Route>
        </Switch>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
