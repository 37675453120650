import { useContext, useEffect, useState } from 'react';
import { Header, Divider } from 'semantic-ui-react'
import AppContext from "../../contexts/appContext";
import { log } from "../../modules/logger";
import { listSessions } from '../../actions/sessionsActions';

import Stream from "../Stream/Stream";
import NoStream from "../NoStream/NoStream";

import './Viewer.css';

const moduleName = "scr-viewer";

function Viewer({ dispatch }) {

  const appState = useContext(AppContext);

  const [sessions, setSessions] = useState([]);

  useEffect(async () => {
    listSessions(dispatch).then(() => {});
  }, [dispatch])

  useEffect(() => {
    async function waitAndAsk() {
      setTimeout(async () => {
        await listSessions(dispatch);
      }, 15000);
    }
    log(moduleName, "sessions changed", { sessions: appState.rooms });
    setSessions(appState.rooms);
    if (appState.rooms.length === 0) {
      waitAndAsk().then(() => {});
    }
  }, [appState.rooms]);

  const renderSessions = () => {
    if (!!sessions.length) {
      return (
        sessions.map((room, index) => (
          <Stream room={room} key={index} dispatch={dispatch} />
        ))
      )
    }
    return (
      <div className="Stream-Video">
        <div className="Stream-Play">
          <NoStream reason="nostream" />
        </div>
      </div>
    );
  };

  const VIEWER = {
    TITLE: "Die App für Einzelhändler",
    SUBTITLE: "die Ihre Kunden von Amazon zurück wollen",
    TODAY: "Aktueller Stream",
    LIVE: "Version 20240125",
    COPYRIGHT: "(c) kkmg.ch 2024"
  }

  return (
    <div className="Viewer">
      <section className="Viewer-background">
        <div className="Viewer-title">
          <h1 className="Viewer-header-text">{VIEWER.TITLE}</h1>
          <h4 className="Viewer-header-subtitle">{VIEWER.SUBTITLE}</h4>
        </div>
      </section>
      <section className="Viewer-live-area">
        <div className="Viewer-live">
          <Header as='h2' icon textAlign='center'>
            <Header.Content className="Viewer-live-title">{VIEWER.TODAY}</Header.Content>
          </Header>
          <Divider horizontal className="Viewer-live-subtitle">{VIEWER.LIVE}</Divider>
        </div>
      </section>
      <div className="Viewer-sessions">
        {renderSessions()}
      </div>
      <footer className="Viewer-footer">
        <p>{VIEWER.COPYRIGHT}</p>
      </footer>
    </div>
  );
}

export default Viewer;
